iframe {
  display: none;
}

.textField {
  background: #fdcd06;
  border-radius: 5px;
  color: #fff;
}

.GradientBorder {
  position: relative;
  padding: 20px 0;
  background: #020117;
  width: 1200px;
}

.GradientBorder::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -3px;
  border-radius: 24px;
  background: linear-gradient(45deg, #0f0, #0cbcf2, #750cf2);
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}
textarea:focus,
input:focus {
  outline: none;
}

.GradientBorder::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
  background: linear-gradient(45deg, #ffd800, #ff5520, #750cf2, #0cbcf2);
  z-index: -2;
  opacity: 0;
  filter: blur(1px);
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.glass-effect {
  display: flex;
  margin: 0px 10px 20px 0px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid #fdcd06;
}

.icon-stats {
  border-radius: 14px;
  background: #fdcd06;
  padding: 8px 10px;
  align-items: center;
}

.glass-effect-moxie {
  padding: 20px 10px;
  background: rgba(123, 63, 228, 0.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 0px solid rgb(253,205,6, 0.6);
}

.glass-effect-gradient-kickto {
  padding: 20px 10px;
  background-image: linear-gradient(to bottom, rgba(123, 63, 228, 0.2), transparent);

  border: 0px solid rgb(253,205,6, 0.2);
}
.nav-colored {
  background-color: #000;
}
.nav-transparent {
  background-color: transparent;
}
.navbar-fixed-top.scrolled {
  background-color: #fff !important;
  transition: background-color 200ms linear;
}
.img-container {
  height: 48px;
  width: 48px;
}

.body-wrapper {
  margin-top: 100px;
}

@media screen and (max-width: 480px) {
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .glass-effect-gradient-moxie {
    /* From https://css.glass */
    padding: 20px 10px;
    background-image: linear-gradient(to bottom, rgba(123, 63, 228, 0.2), transparent);
    border: 0px solid rgba(123, 63, 228, 0.6);
  }
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px 0px;
}
.search-input {
  width: 500px;
}
@media screen and (max-width: 600px) {
  .body-wrapper {
    margin-top: 20px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    padding: 10px 0px;
  }
  .glass-effect {
    margin: 0px 0px 20px 0px;
  }
  .search-input {
    width: 100%;
  }
  div {
    font-size: 14px;
  }
  p {
    font-size: 14px;
  }
  h1 {
    font-size: 14px;
  }
  h2 {
    font-size: 14px;
  }
  h3 {
    font-size: 14px;
  }
  h4 {
    font-size: 14px;
  }
  .custom-grid {
    justify-content: center;
  }
}

@media screen and (max-width: 900px) and (min-width: 600px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px 0px;
  }
  .glass-effect {
    margin: 0px 10px 20px 0px;
  }
  .search-input {
    width: 100%;
  }
}

@media screen and (max-width: 600px) and (min-width: 480px) {
  div {
    font-size: 12px;
  }
  p {
    font-size: 12px;
  }
  h1 {
    font-size: 12px;
  }
  h2 {
    font-size: 12px;
  }
  h3 {
    font-size: 12px;
  }
  h4 {
    font-size: 12px;
  }

  .img-container {
    height: 36px;
    width: 36px;
  }
}
.grid-item {
  font-size: 30px;
  text-align: left;
}

.parent {
  display: flex;
  justify-content: space-between;
}

.left {
  width: 100%;
  text-align: left;
}

.right {
  width: 100%;
  text-align: right;
}
